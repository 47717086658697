import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
	const [auth, setAuth] = useState(false);
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(false);
	const [active, setActive] = useState("");

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				loading,
				setLoading,
				user,
				setUser,
				active,
				setActive,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};
