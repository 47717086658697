import React, { createContext, useState } from "react";

export const SearchFlatMateContext = createContext();

export const SearchFlatMateProvider = (props) => {
	const [filter, setFilter] = useState({
		movingCity: "",
		homeState: "",
		education: "",
		jobCategory: "",
		genderPreference: "",
		occupation: "",
	});

	const [page, setPage] = useState(1);

	const [preferredAge, setPreferredAge] = useState({ min: 15, max: 70 });

	return (
		<SearchFlatMateContext.Provider
			value={{
				filter,
				setFilter,
				preferredAge,
				setPreferredAge,
				page,
				setPage,
			}}
		>
			{props.children}
		</SearchFlatMateContext.Provider>
	);
};
