import React from "react";
import { footerlink1, footerlink2 } from "../assets/footerlinks";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = () => {
	return (
		<React.Fragment>
			<footer className="bg-dark text-light">
				<Row>
					<Col md={3} className="my-auto">
						<img
							src={process.env.PUBLIC_URL + "/images/FlatsAndFlatmates.png"}
							alt="logo"
							style={{ height: "110px", width: "224px" }}
						/>
					</Col>
					<Col md={3}>
						<ul>
							<li>
								<i className="fa fa-map-marker left social-icon"></i>
								NSRCeL, IIM Bangalore, Bannerghatta Road, Bengaluru, 560095
							</li>
							<li>
								<i className="fa fa-envelope left social-icon"></i>
								<a href="mailto:hello@flatsandflatmates.co.in">
									hello@flatsandflatmates.co.in
								</a>
							</li>
							<li className="text-center mt-4">
								<a
									href="https://www.facebook.com/FlatsandFlatmatess/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-facebook social-icon"></i>
								</a>
								<a
									href="https://twitter.com/FlatsnFlatmates"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-twitter social-icon"></i>
								</a>
								<a
									href="https://www.instagram.com/flatsnflatmates/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-instagram social-icon"></i>
								</a>
							</li>
						</ul>
					</Col>
					<Col md={3}>
						<ul>
							{footerlink1.map((data, index) => {
								return (
									<li key={index} className="footer-list">
										<Link to={data.path}>{data.name}</Link>
									</li>
								);
							})}
						</ul>
					</Col>
					<Col md={3}>
						<ul>
							{footerlink2.map((data, index) => {
								return (
									<li key={index} className="footer-list">
										<Link to={data.path}>{data.name}</Link>
									</li>
								);
							})}
						</ul>
					</Col>
				</Row>
				<Row className="mt-4 text-center">
					<Col md={3}>
						<div className="footer-list d-inline mr-4">Proud Partners</div>
					</Col>
					<Col md={3}>
						<a
							target="_blank"
							href="https://www.go-gaga-app.com/"
							rel="noopener noreferrer"
						>
							<img
								src={process.env.PUBLIC_URL + "/images/Gogaga_logo.png"}
								alt="Go Gaga"
							/>
						</a>
					</Col>
					<Col md={3}>
						<a
							target="_blank"
							href="https://rentbaaz.com/"
							rel="noopener noreferrer"
						>
							<img
								src={process.env.PUBLIC_URL + "/images/rentbaz.png"}
								alt="Go Gaga"
							/>
						</a>
					</Col>
					<Col md={3}>
						<a
							href="https://settlrs.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={process.env.PUBLIC_URL + "/images/settlers.png"}
								alt="Go Gaga"
							/>
						</a>
					</Col>
				</Row>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
