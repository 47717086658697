import React, { createContext, useState } from "react";

export const MapContext = createContext();

export const MapProvider = (props) => {
	const [markerNumber, setMarkerNumber] = useState([]);

	return (
		<MapContext.Provider value={{ markerNumber, setMarkerNumber }}>
			{props.children}
		</MapContext.Provider>
	);
};
