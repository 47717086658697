import React from "react";

const Loader = () => {
	return (
		<div className="onload">
			<img
				src={process.env.PUBLIC_URL + "/images/loader.svg"}
				alt="Loading..."
			/>
		</div>
	);
};

export default Loader;
