import React, { createContext, useState } from "react";

export const SearchPropertyContext = createContext();

export const SearchPropertyProvider = (props) => {
	const [filter, setFilter] = useState({
		totalBedrooms: "",
		preferredGender: "",
		preferredOccupation: "",
		location: "",
		roomType: "",
	});
	const [page, setPage] = useState(1);
	const [age, setAge] = useState({ min: 15, max: 70 });
	const [rent, setRent] = useState({ min: 1000, max: 50000 });

	return (
		<SearchPropertyContext.Provider
			value={{ filter, setFilter, age, setAge, rent, setRent, page, setPage }}
		>
			{props.children}
		</SearchPropertyContext.Provider>
	);
};
