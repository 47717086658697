const footerlink1 = [
	{
		name: "Home",
		path: "/",
	},
	{
		name: "Search Flatmates",
		path: "/searchflatmates",
	},
	{
		name: "Search Properties",
		path: "/searchProperty",
	},
	{
		name: "Post a Property",
		path: "/postProperty",
	},
	{
		name: "Blogs",
		path: "/blogs",
	},
	{
		name: "Join Groups",
		path: "/joinGroups",
	},
];

const footerlink2 = [
	{
		name: "About Us",
		path: "/about",
	},
	{
		name: "Careers",
		path: "/careers",
	},
	{
		name: "Contact",
		path: "/contact",
	},
	{
		name: "FAQs",
		path: "/faqs",
	},
	{
		name: "Terms & Conditions",
		path: "/termAndConditions",
	},
];

export { footerlink1, footerlink2 };
