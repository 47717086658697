const navItem = [
	{
		name: "Home",
		path: "/",
	},
	{
		name: "Search Flatmates",
		path: "/searchflatmates",
	},
	{
		name: "Search Properties",
		path: "/searchProperty",
	},
	{
		name: "Post A Property",
		path: "/postProperty",
	},
	{
		name: "Join Groups",
		path: "/joinGroups",
	},
];

export default navItem;
