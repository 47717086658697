import React from "react";
import { Container, Row, Col } from "react-bootstrap";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<div>
					<Container>
						<Row>
							<Col
								sm={12}
								style={{ height: "100vh", width: "100vw" }}
								className="d-flex justify-content-center align-items-center flex-column"
							>
								<i
									className="fa fa-exclamation-circle mx-4 text-danger display-1"
									aria-hidden="true"
								></i>
								<h1 className="text-danger text-center">
									Something went wrong. Try refreshing!
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;

//   to show errors on screen
//   <h2>Something went wrong.</h2>
//   <details style={{ whiteSpace: 'pre-wrap' }}>
//     {this.state.error && this.state.error.toString()}
//     <br />
//     {this.state.errorInfo.componentStack}
//   </details>
