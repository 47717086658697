import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import { database } from "firebase";

const BottomNav = () => {
	const { auth, active, setActive } = useContext(AuthContext);
	const bottomNavMenu = [
		{
			name: "Home",
			icon: "fa fa-home",
			path: "/",
			protected: false,
		},
		{
			name: "Search",
			icon: "fa fa-search",
			path: "/searchProperty",
			protected: false,
		},
		{
			name: "Post",
			icon: "fa fa-plus-circle",
			path: "/postProperty",
			protected: false,
		},
		{
			name: "Profile",
			icon: "fa fa-user",
			path: "/profile",
			protected: true,
		},
	];

	return (
		<Row className="bg-dark bottomNavWrapper m-0 w-100 pb-0">
			<div className="w-100 d-flex justify-content-around text-capitalize text-center m-0 pt-2 pb-0">
				{bottomNavMenu.map((data, index) => {
					if (!data.protected || (data.protected && auth)) {
						return (
							<div key={index}>
								<Link
									to={data.path}
									className={
										data.path === active
											? "text-decoration-none text-white"
											: "text-white-50 text-decoration-none"
									}
								>
									<i className={data.icon}></i>
									<p>{data.name}</p>
								</Link>
							</div>
						);
					}
				})}
				{!auth && (
					<div>
						<Link
							to={"/signin"}
							className={
								"/signin" === active
									? "text-decoration-none text-white"
									: "text-white-50 text-decoration-none"
							}
						>
							<i className="fa fa-sign-in"></i>
							<p>SignIn</p>
						</Link>
					</div>
				)}
			</div>
		</Row>
	);
};

export default BottomNav;
