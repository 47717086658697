export const getToken = () =>{
    const token = localStorage.getItem('token')
    if(!token || token.length === 0){
        return null
    }else{
        return token
    }
}

export const setToken = (token) =>{
    localStorage.setItem('token' , token)
}

export const removetoken = () =>{
    localStorage.removeItem('token')
}