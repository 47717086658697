import firebase from './assets/firebase'

export const askForPermissionToRecieveMessage = async () => {
	try{
		const messaging = firebase.messaging();
		await Notification.requestPermission()
		const token = await messaging.getToken()
        localStorage.setItem('notification_token', token)
		navigator.serviceWorker.addEventListener("message", (message) => console.log('push notif message: ',message));
	}catch(err) {
		console.log('push notif message err: ',err)
	}
}
