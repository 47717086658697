export const initialStep = {
	step: 1,
	stepInfo: "Property Information",
};

export const initialState = {
	propertyType: "",
	roomType: "",
	propertyState: "",
	availableRooms: "",
	totalBedrooms: "",
	totalBathrooms: "",
	rent: "",
	roomArea: "",
	utilityCost: 0,
	avaliableFrom: new Date(),
	minimumStay: "",
	numOfMatesLiving: "",
	preferredGender: "",
	preferredOccupation: "",
	propertyAmenities: [],
	permissibleRules: [],
	advertisingAs: "",
	description: "",
	title: "",
	notificationEnabled: false,
	isEnabled: true,
};

export const initialAge = { from: 20, to: 35 };

export const initialAddress = {
	landmark: null,
	locality: null,
	city: null,
	state: null,
	pinCode: null,
	lat: 12.9716,
	lng: 77.5946,
};

export const initialCity = null;

export const initialPropertyImages = [];

export const images = [];
