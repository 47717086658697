var url = "";

if (
	document.URL.startsWith("http://localhost") ||
	document.URL.startsWith("https://staging-ui") ||
	document.URL.startsWith("http://staging-ui")
)
	url = "https://staging-server.flatsandflatmates.co.in";
else url = "https://restnew.flatsandflatmates.co.in";

export default url;
