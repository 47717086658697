import firebase from "firebase";

const config = {
	apiKey: "AIzaSyCzkZ2BvpMv6FV5PsS9up3lj620_v-ZebI",
	authDomain: "nayaroomie-278710.firebaseapp.com",
	databaseURL: "https://nayaroomie-278710.firebaseio.com",
	projectId: "nayaroomie-278710",
	storageBucket: "nayaroomie-278710.appspot.com",
	messagingSenderId: "788384023921",
	appId: "1:788384023921:web:3b30ee7a493d1a2ebd22d0",
	measurementId: "G-KTXLXYD073",
};

firebase.initializeApp(config);

export default firebase;
