import React, { createContext, useState } from "react";
import {
	initialState,
	initialAge,
	initialAddress,
	initialCity,
	initialPropertyImages,
	initialStep,
} from "../helper/postPropertInitialState";

export const EditPropertyContext = createContext();

export const EditPropertyProvider = (props) => {
	const [step, setStep] = useState({ ...initialStep });

	const [state, setState] = useState({ ...initialState });

	const [preferredAge, setPreferredAge] = useState({ ...initialAge });

	const [address, setAddress] = useState({ ...initialAddress });

	const [city, setCity] = useState(initialCity);

	const [propertyImages, setPropertyImages] = useState([
		...initialPropertyImages,
	]);
	const [images, setImages] = useState([]);

	const [nearByProperty, setNearByProperty] = useState([]);

	return (
		<EditPropertyContext.Provider
			value={{
				step,
				setStep,
				state,
				setState,
				preferredAge,
				setPreferredAge,
				address,
				setAddress,
				propertyImages,
				setPropertyImages,
				images,
				setImages,
				city,
				setCity,
				nearByProperty,
				setNearByProperty,
			}}
		>
			{props.children}
		</EditPropertyContext.Provider>
	);
};
