import React, { useContext, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import navlinks from "../assets/navlinks";
import { useState } from "react";
import { useRef } from "react";
import { protectedGetRequest } from "../requests/request";
import { Badge } from "react-bootstrap";

const Nb = () => {
	const { auth, active, setActive } = useContext(AuthContext);
	const history = useHistory();
	const [unread, setUnread] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const navRef = useRef(null);

	useEffect(() => {
		setActive(() => history.location.pathname);
	}, [history.location.pathname]);

	const getNotification = async () => {
		const path = "unreadChats";
		const { success, data } = await protectedGetRequest(path);
		if (success) {
			setUnread((unread) => data.data.unreadCount);
		}
	};
	if (auth) {
		getNotification();
	}

	const handelHamburgerClick = () => {
		setIsOpen((isOpen) => !isOpen);
	};

	const hideNav = () => {
		setIsOpen((isOpen) => false);
	};

	return (
		<React.Fragment>
			<Navbar
				expand="lg"
				id="navBar"
				style={{ padding: "0px 10px" }}
				className="bg-dark"
			>
				<Navbar.Brand href="/" className="text-light brand">
					<img
						src={process.env.PUBLIC_URL + "/images/FlatsAndFlatmates.png"}
						alt="logo"
						style={{ height: "75px", width: "152px" }}
					/>
				</Navbar.Brand>
				<Navbar.Toggle
					aria-controls="basic-navbar-nav"
					onClick={handelHamburgerClick}
				/>
				<Navbar.Collapse id="basic-navbar-nav" in={isOpen}>
					<Nav className="ml-auto" ref={navRef}>
						<Nav.Item onClick={hideNav} className="nav__crossButton">
							<i className="fa fa-times text-light text-right w-100 p-4"></i>
						</Nav.Item>
						{navlinks.map((item, index) => {
							return (
								<Nav.Item key={index} onClick={hideNav}>
									<Link
										to={item.path}
										className={
											active === item.path ? "nav-active listItem" : "listItem"
										}
									>
										{item.name}
									</Link>
								</Nav.Item>
							);
						})}
						{!auth && (
							<>
								<Nav.Item onClick={hideNav}>
									<Link
										to="/signin"
										className={
											active === "/signin" ? "nav-active listItem" : "listItem"
										}
									>
										Sign In
									</Link>
								</Nav.Item>
							</>
						)}
						{auth && (
							<>
								<Nav.Item onClick={hideNav}>
									<Link
										to="/profile"
										className={
											active === "/profile" ? "nav-active listItem" : "listItem"
										}
									>
										Profile
									</Link>
								</Nav.Item>
								<Nav.Item onClick={hideNav}>
									<Link
										to="/message"
										className={
											active === "/message" ? "nav-active listItem" : "listItem"
										}
									>
										Message
										{unread !== 0 && (
											<Badge variant={"light"} className="ml-2">
												{unread}
											</Badge>
										)}
									</Link>
								</Nav.Item>
							</>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</React.Fragment>
	);
};

export default Nb;
