import Axios from 'axios'
import url from '../url';
import { getToken } from './token';

// @access PUBLIC
// @method get
export const unProtectedGetRequest = async (path='') => {
    try {
        const res = await Axios.get(`${url}/${path}`, { withCredentials: true })
        return { success: res.data.success, data: res }
    } catch (error) {
        return { success: false, data: error }
    }
}

// @access PUBLIC
// @method post
export const unProtectedPostRequest = async ({ path='', payload='' }) => {
    try {
        const res = await Axios.post(`${url}/${path}`, payload , { withCredentials: true })
        return { success: res.data.success, data: res }
    } catch (error) {
        return { success: false, data: error }
    }
}

// @access PRIVATE
// @method get
export const protectedGetRequest = async (path='') => {
    const token = getToken()
    const config = {
        headers:{
            'x-auth-token': token
        },
        withCredentials: true
    }

    try {
        const res = await Axios.get(`${url}/${path}`, config)
        return { success: res.data.success, data: res }
    } catch (error) {
        return { success: false, data: error }
    }
}

// @access PRIVATE
// @method post
export const protectedPostRequest = async ({ path='', payload='' }) => {
    const token = getToken()
    const config = {
        headers:{
            'x-auth-token': token
        },
        withCredentials: true
    }

    try {
        const res = await Axios.post(`${url}/${path}`, payload, config)
        return { success: res.data.success, data: res }
    } catch (error) {
        return { success: false, data: error }
    }
}

// @access PRIVATE
// @method post images
export const uploadImages = async ({path='', images, id}) =>{
    const token = getToken()
    const image = new FormData()
    image.append('propertyId', id)
    for(let i=0 ; i<images.length ; i++){
        if(i<5){
            image.append('images', images[i].file, images[i].file.name )
        }
    }

    const config = {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${image._boundary}`,
            'x-auth-token': token
        },
        withCredentials: true
    }
    try {
        const res = await Axios.post(`${url}/${path}`, image, config)
        return { success: res.data.success, data: res }
    } catch (error) {
        return { success: false, data: error }
    }
} 