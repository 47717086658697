import "./css/App.css";
import "./css/footer.css";
import "./css/instagram.css";
import "./css/loader.css";
import "./css/navBar.css";
import "./css/customInput.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./css/uploadImage.css";
import "./css/propertyCard.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./css/propertyInfoIcons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-input-range/lib/css/index.css";
import "./css/filters.css";
import "./css/Testimonials.css";
import "./css/searchpropertyForm.css";
import "./css/profilePicUpload.css";
import "./css/user.css";
import "./css/About.css";
import "./css/Home.css";
import "react-chat-elements/dist/main.css";
import "./css/chat.css";
import "./css/multiStepForm.css";
import "./css/infoWindow.css";
import "./css/bottomNav.css";

import React, { useContext, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import { MapContext } from "./context/mapContext";
import { protectedGetRequest, unProtectedGetRequest, protectedPostRequest } from "./requests/request";
import { LastLocationProvider } from "react-router-last-location";
import firebase from './assets/firebase'

import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import Loader from "./components/loader";
import BottomNav from "./components/bottomNav";
import { askForPermissionToRecieveMessage } from "./push-notification";

const Home = React.lazy(() => import("./pages/Home"));
const Auth = React.lazy(() => import("./pages/Auth"));
const PostProperty = React.lazy(() => import("./pages/PostProperty"));
const SearchFlatemates = React.lazy(() => import("./pages/SearchFlatemates"));
const SearchProperty = React.lazy(() => import("./pages/SearchProperty"));
const Careers = React.lazy(() => import("./pages/Careers"));
const ContactUS = React.lazy(() => import("./pages/Contact"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const TermsAndConditions = React.lazy(() =>
	import("./pages/TermsAndConditions")
);
const Blogs = React.lazy(() => import("./pages/Blogs"));
const About = React.lazy(() => import("./pages/About"));
const EditProfile = React.lazy(() => import("./pages/EditProfile"));
const PagenotFound = React.lazy(() => import("./pages/PageNotFound"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));
const ViewProprty = React.lazy(() => import("./pages/ViewProperty"));
const ViewUser = React.lazy(() => import("./pages/ViewUser"));
const Blog = React.lazy(() => import("./components/Blog"));
const Message = React.lazy(() => import("./pages/Message"));
const MessageScreen = React.lazy(() => import("./pages/MessageScreen"));
const EditProperty = React.lazy(() => import("./pages/EditProperty"));
const NotAuthorized = React.lazy(() => import("./pages/notAuthorized"));
const SocailGroups = React.lazy(() => import("./pages/SocialGroups"));

function App() {
	const { auth, loading, setAuth, setUser, setLoading } = useContext(
		AuthContext
	);
	const { setMarkerNumber, markerNumber } = useContext(MapContext);

	// Check User Auth on initial load
	useEffect(() => {
		const CheckStatus = async () => {
			setLoading((loading) => true);

			const path = "checkStatus";
			const { success, data } = await protectedGetRequest(path);
			if (success) {
				setUser(data.data.user);
				setAuth((auth) => true);
			} else setAuth((auth) => false);

			setLoading((loading) => false);
		};

		const getCityTotalProperties = async () => {
			const path = "getCityTotalProperties";
			const { success, data } = await unProtectedGetRequest(path);
			if (success) setMarkerNumber(data.data.report);
		};

		CheckStatus();
		getCityTotalProperties();
	}, []);

	useEffect(()=> {
		const sentTokenToServer = async () => {
			var path = 'notifToken'
			var token = await localStorage.getItem('notification_token')
			if(!token) { token = await firebase.messaging().getToken }
			var payload = {notifToken: token}
			var { success, data } = await protectedPostRequest({ path, payload })
			if(success) console.log('token sent successufully: ',success)
		}

		if(auth){
			if(!localStorage.getItem('notification_token')) {
				askForPermissionToRecieveMessage()
				sentTokenToServer()
			}
			const getNotif = async () => {
				firebase.messaging().onTokenRefresh(sentTokenToServer())
				var token = await localStorage.getItem('notification_token')
				var { success, data } = await protectedPostRequest({ path: 'sendTestNotif', payload: token })
				console.log(success, data)
			}
			getNotif()
			
		}
	},[auth])


	return (
		<React.Fragment>
			<Router>
				<LastLocationProvider>
					<Navbar />
					{/* react.suspence for better performance */}
					<Suspense
						fallback={
							<div
								style={{ height: "500px", width: "100vw !important" }}
								className="bg-dark p-0 m-0"
							>
								<Loader />
							</div>
						}
					>
						<Switch>
							{/* Home route */}
							<Route exact path="/" component={Home} />

							{/* Static Routes */}
							<Route exact path="/about" component={About} />
							<Route exact path="/careers" component={Careers} />
							<Route exact path="/contact" component={ContactUS} />
							<Route exact path="/faqs" component={FAQs} />
							<Route
								exact
								path="/termAndConditions"
								component={TermsAndConditions}
							/>
							<Route exact path="/blogs" component={Blogs} />
							<Route exact path="/blogs/:name" component={Blog} />
							<Route exact path="/joinGroups" component={SocailGroups} />

							{/* Auth Route */}
							<Route exact path="/signin" component={Auth} />

							{/* User Routes */}
							<Route exact path="/profile" component={UserProfile} />
							<Route exact path="/editProfile" component={EditProfile} />
							<Route exact path="/user/:id" component={ViewUser} />
							<Route exact path="/message" component={Message} />
							<Route exact path="/message/:id" component={MessageScreen} />

							{/* Action Routes */}
							<Route exact path="/postProperty" component={PostProperty} />
							<Route
								exact
								path="/searchflatmates"
								component={SearchFlatemates}
							/>
							<Route exact path="/searchProperty" component={SearchProperty} />
							<Route
								exact
								path="/viewProperty/:name/:id/:img"
								component={ViewProprty}
							/>
							<Route exact path="/editProperty/:id" component={EditProperty} />

							{/* Not Authorize Page */}
							<Route path="/not-authorized" component={NotAuthorized} />

							{/* Page Not Found */}
							<Route path="*" component={PagenotFound} />
						</Switch>
					</Suspense>
					<Footer />
					<BottomNav />
				</LastLocationProvider>
			</Router>
		</React.Fragment>
	);
}

export default App;
