import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
	const [editUser, setEditUser] = useState({
		fname: "",
		lname: "",
		email: "",
		gender: "",
		phone: "",
		dob: new Date(),
		profilePic: "",
		homeTowm: "",
		homeState: "",
		jobCategory: "",
		occupation: "",
		movingCity: "",
		currentCity: "",
		description: "",
		education: "",
		flatmateRequestEnabled: true,
		genderPreference: "Male",
	});

	const [myLifestylePreferences, setMyLifestylePreferences] = useState({
		foodHabits: "",
		drinkingStyle: "",
		smokingHabit: "",
		petsPreferences: "",
		musicPreferences: "",
		sleepingPattern: "",
		relationShipStatus: "",
		sexualOrientation: "",
	});

	const [flatMatePreferences, setFlatMatePreferences] = useState({
		foodHabits: "",
		drinkingStyle: "",
		smokingHabit: "",
		petsPreferences: "",
		musicPreferences: "",
		sleepingPattern: "",
		relationShipStatus: "",
		sexualOrientation: "",
	});

	return (
		<UserContext.Provider
			value={{
				editUser,
				setEditUser,
				myLifestylePreferences,
				setMyLifestylePreferences,
				flatMatePreferences,
				setFlatMatePreferences,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};
