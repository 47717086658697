import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./context/authContext";
import { PostPropertyProvider } from "./context/postPropertyContext";
import { UserProvider } from "./context/userContext";
import { ToastProvider } from "react-toast-notifications";
import "./css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { EditPropertyProvider } from "./context/editProperty";
import { SearchFlatMateProvider } from "./context/searchFlatMateContext";
import { SearchPropertyProvider } from "./context/searchPropertyContext";
import { MapProvider } from "./context/mapContext";
import ErrorBoundary from "./components/ErrorBoundries";
import firebase from './assets/firebase'

ReactDOM.hydrate(
	<React.StrictMode>
		<ErrorBoundary>
			<ToastProvider autoDismiss autoDismissTimeout={5000}>
				<AuthProvider>
					<UserProvider>
						<MapProvider>
							<PostPropertyProvider>
								<EditPropertyProvider>
									<SearchFlatMateProvider>
										<SearchPropertyProvider>
											<App />
										</SearchPropertyProvider>
									</SearchFlatMateProvider>
								</EditPropertyProvider>
							</PostPropertyProvider>
						</MapProvider>
					</UserProvider>
				</AuthProvider>
			</ToastProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);


if ('serviceWorker' in navigator) {
navigator.serviceWorker.register('../firebase-messaging-sw.js', { scope: 'firebase-cloud-messaging-push-scope' })
	.then(function(registration) {
		firebase.messaging().useServiceWorker(registration); 
		console.log('Registration successful, scope is:', registration.scope);
	}).catch(function(err) {
		console.log('Service worker registration failed, error:', err);
	});
}

serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
